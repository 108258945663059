import './assets/main.css';
import { createApp, type App as VueApp } from 'vue';
import App from './App.vue';
import router from '@/router';
import translator from '@/plugins/translator';
import { pinia } from '@/store';
import { i18n } from '@/locale/i18n';
import { dynamicFormService } from './dynamic-form/dynamic-form-service';
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg';

import 'vuetify/styles';
import { createVuetify, type ThemeDefinition } from 'vuetify';
import '@fontsource/source-sans-pro';
import '@fontsource/source-sans-pro/600.css';
import { initSentry } from './sentry';
import { testHelper } from './helpers/test-helper';

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    scannerAppInstance: VueApp<Element>,
    appReady: boolean
  }
}

const einfachGastV2LightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: '#4290ce',
    'primary-darken-1': '#3700B3',
    secondary: '#03DAC6',
    'secondary-darken-1': '#018786',
    error: '#F44336',
    red: '#F44336',
    info: '#2196F3',
    success: '#1bbc65',
    warning: '#FB8C00',
    'on-success': '#FFFFFF',
  },
};

const vuetify = createVuetify({
  theme: {
    defaultTheme: 'einfachGastV2LightTheme',
    themes: {
      einfachGastV2LightTheme,
    },
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
});

const app = createApp(App);

initSentry(app, router);

app.use(vuetify);
app.use(pinia);
app.use(router);
app.use(i18n);
app.use(translator);

dynamicFormService.init();

app.mount('#app');

// we need this to access the app instance on E2E testing
if(testHelper.isEndToEndTest) {
  window.scannerAppInstance = app;
}

export { app };